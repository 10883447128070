import Head from 'next/head'
import 'regenerator-runtime/runtime'
import { CREATE_SUB_MENU, ROUTES } from '../constants/navigation'
import { withHeaderOptions } from '../hoc/withHeaderOptions'
import { getPerenderedChallenges, getPrerenderedTemplates } from '../queries/create'
import { getPrerenderedInspirations } from '../queries/posts'
import CreatePage from '../routes/Create'
import { addDisableOverscroll, addRouteType, addSecondaryMenu } from '../utils/navigation'

const Routes = ({ templates, challenges, userEvents, inspirations, isMobile }) => {
  return (
    <>
      <Head>
        <title>Room Design App | Play Interior Design Games - DecorMatters</title>
        <meta
          name="description"
          content="Explore the best room design app at DecorMatters. This virtual staging home design app is perfect for interior designers and anyone who wants to plan a room design online. Play decorating games and sign up for our interior design app at DecorMatters."
        />
        <meta name="twitter:title" content="Room Design App | Play Interior Design Games - DecorMatters" />
        <meta property="og:title" content="Room Design App | Play Interior Design Games - DecorMatters" />
        <meta
          property="og:description"
          content="Explore the best room design app at DecorMatters. This virtual staging home design app is perfect for interior designers and anyone who wants to plan a room design online. Play decorating games and sign up for our interior design app at DecorMatters."
        />
        <meta property="og:site_name" content="DecorMatters" />
        <meta name="google-site-verification" content="tc8tm4ArNtKEDRKnlRcx3Yci9kajD8h5x1vtRdrbOM0" />
      </Head>
      <CreatePage isMobile={isMobile} templates={templates} challenges={challenges} userEvents={userEvents} inspirations={inspirations} />
    </>
  )
}

export default withHeaderOptions(Routes)(addSecondaryMenu(CREATE_SUB_MENU), addRouteType(ROUTES.HOMEPAGE), addDisableOverscroll())

export async function getStaticProps() {
  try {
    const inspirations = await getPrerenderedInspirations()
    const templates = await getPrerenderedTemplates()
    const challenges = await getPerenderedChallenges()

    return {
      props: {
        inspirations: inspirations?.result?.userInspirations.slice(0, 9),
        templates: templates?.result?.templates,
        challenges: challenges?.result?.designContests,
        disableModals: true
      },
      revalidate: 3600
    }
  } catch (error) {
    console.log(error)
    return {
      props: {}
    }
  }
}
