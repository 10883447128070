import Image from 'next/image'
import articleLogoImg from '../../../assets/seo/Article.png'
import cb2LogoImg from '../../../assets/seo/cb2.png'
import crateBarrelLogoImg from '../../../assets/seo/crate_barrel.png'
import dwrLogoImg from '../../../assets/seo/DWR.png'
import ikeaLogoImg from '../../../assets/seo/Ikea.png'
import pbkLogoImg from '../../../assets/seo/pbk.png'
import rhLogoImg from '../../../assets/seo/RH.png'
import wayfairLogoImg from '../../../assets/seo/wayfair.png'
import westElmLogoImg from '../../../assets/seo/Westelm.png'
import { DesktopOnlyBlock, GradientButton, ProductImage, SeoLogoCard, SeoSectionInfo, SeoSectionLogos, ShoppingSection, ShoppingSectionContainer } from '../Create.styles'

const HomeShopping = () => {
  return (
    <ShoppingSectionContainer>
      <ShoppingSection>
        <DesktopOnlyBlock>
          <ProductImage>
            <Image width={407} height={527} src={'https://cdn.decormatters.com/assets/rating.webp'} alt="The Leading Home Design App That Makes Shopping Enjoyable" />
          </ProductImage>
        </DesktopOnlyBlock>
        <SeoSectionInfo>
          <h2>The Leading Home Design App for Convenient Shopping</h2>
          <p>
            DecorMatters is a top-ranked room design app where you can browse, design, and shop furniture from over 30 popular brands. This powerful AR home design tool lets you virtually place items in your home to try before you buy. Make smart
            interior design choices by decorating with both store and personal items to see how they fit together. Enjoy a seamless home design and home improvement experience from start to finish.
          </p>
          <SeoSectionLogos>
            <SeoLogoCard>
              <img src={crateBarrelLogoImg} loading="lazy" alt="Crate & Barrel" />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={cb2LogoImg} loading="lazy" alt="CB2" width={97} height={37} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={articleLogoImg} loading="lazy" alt="Article" width={97} height={36} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={westElmLogoImg} loading="lazy" alt="West Elm" width={97} height={34} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={ikeaLogoImg} loading="lazy" alt="IKEA" width={97} height={39} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={wayfairLogoImg} loading="lazy" alt="Wayfair" width={97} height={35} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={pbkLogoImg} loading="lazy" alt="Pottery Barn Kids" width={97} height={35} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img src={dwrLogoImg} loading="lazy" alt="Design Within Reach" width={97} height={42} />
            </SeoLogoCard>
            <SeoLogoCard>
              <img width={43} height={24} src={rhLogoImg} loading="lazy" alt="Restoration Hardware" />
            </SeoLogoCard>
          </SeoSectionLogos>
          <GradientButton
            onClick={() => {
              openLogin()
              logEvent('start_designing_click', {})
            }}
          >
            Start Designing
          </GradientButton>
        </SeoSectionInfo>
      </ShoppingSection>
    </ShoppingSectionContainer>
  )
}

export default HomeShopping
